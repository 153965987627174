import axios from "axios";
import constants from './constants'

export default axios.create({
    baseURL: constants.BASE_URL,
    timeout: 120000,
    credentials: 'same-origin',
    headers: {
        //'Authorization': 'Bearer ' + token, 
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Ocp-Apim-Subscription-Key': constants.apiMKey, 
        'Origin': "https://portaldeadesoes.valia.com.br/",
    }
});
 